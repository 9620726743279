import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com"; // Import the EmailJS library
import "./styles/ContactForm.css";

export default function ContactForm({ showImage = true, className = "" }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  useEffect(() => {
    document.title = "Contact - Aestus Health"; // Set the tab name to "Contact - Aestus Health"
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS service parameters
    const serviceID = "service_mksrpyh";
    const templateID = "template_zjro2lc";
    const userID = "tCwPleql2f6kyBUuI";

    const emailData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    emailjs
      .send(serviceID, templateID, emailData, userID)
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        alert("Your message has been sent successfully!");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        alert("There was an issue sending your message. Please try again.");
      });
  };

  return (
    <section className={`contact-section fade-in-page ${className}`}>
      <div className="form-container">
        <h2>Contact</h2>
        <p>Aestus Health |{" "}
          <a 
            href="https://www.google.com/maps/search/?api=1&query=3310+S+Service+Rd+Burlington,+ON+L7N+3M6" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            3310 S Service Rd Burlington, ON
          </a>{" "} |{" "}
          <a href="tel:2897972794">289-797-2794</a>
        </p>
  
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">
              Name <span>*</span>
            </label>
            <div className="name-fields">
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="email">
              Email <span>*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Comment or Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
            />
          </div>

          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>

  

      {showImage && (
        <div className="contact-image">
          <img src="/womanOnPhone.jpg" alt="Contact" />
        </div>
      )}

    
  
    </section>
  );
}
