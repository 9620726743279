import React from "react";
import { useParams } from "react-router-dom"; // Import useParams
import blogPosts from "./blogData"; // Import blog data
import "./styles/BlogPost.css";

export default function BlogPost() {
  const { id } = useParams(); // Get the ID from the URL
  const blogPost = blogPosts.find((post) => post.id === parseInt(id)); // Find the blog post by ID

  // Handle case where blog post doesn't exist
  if (!blogPost) {
    return <p>Blog post not found.</p>;
  }

  return (
    <div className="blog-post">
      {/* Blog Header */}
      <div className="blog-header">
        <img src={blogPost.image} alt="Blog Post" className="blog-image" />
        <div className="blog-info">
          <h3>{blogPost.title}</h3>
          <p className="blog-meta">
           By <a href="#">{blogPost.author}</a>
          </p>
        </div>
      </div>

      {/* Blog Content */}
      <div>
        {blogPost.content.map((item, index) => {
          if (item.type === "text") {
            return <p key={index} dangerouslySetInnerHTML={{ __html: item.value }} />; // Render text with HTML (italic + link)
          } else if (item.type === "image") {
            return <img key={index} src={item.src} alt={item.alt} className="blog-image" />; // Render image
          } else if (item.type === "heading") {
            return <h3 key={index} dangerouslySetInnerHTML={{ __html: item.value }} />; // Render heading
          }
          return null; // In case of an unknown type
        })}
      </div>
    </div>
  );
}
