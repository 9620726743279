import React from "react";
import { useNavigate } from "react-router-dom"; // Import the hook for navigation
import ContactForm2 from "./ContactForm 2";

export default function MainSection() {
  const navigate = useNavigate(); // Initialize navigation function

  const handleBookNowClick = () => {
    navigate("/contact"); // Navigate to the contact page when the button is clicked
  };

  return (
    <section className="main-section">
      <div className="text-section">
        <h2 className="cursive-heading">I am glad you are here</h2>

        <div className="image-section2">
          <img src="/alipic2.png" alt="Ali Muhammad" />
          <figcaption><em>Ali Muhammad - Registered Psychotherapist (Qualifying)</em></figcaption>
        </div>

        <p>
          At Aestus Health, we believe that mental wellness is a journey—one marked by movement, transformation, and resilience. The Latin word aestus carries meanings of tide, surge, heat, and passion, reflecting the natural rhythms of life.
        </p>

        <p>
            Just as the tide ebbs and flows, so do our thoughts and feelings. At Aestus Health, we provide a supportive space to navigate these changes, whether you're facing challenges, seeking growth, or striving for balance. Our approach embraces the warmth of human connection, the power of healing, and the passion for helping you move toward a healthier, more fulfilled life.
            No matter where you are on your journey, we are here to guide you through the waves with compassion, insight, and evidence-based care.
        </p>

        <p>
          Perhaps you're in a season of your life where things aren't going the way you expected them to, you're feeling overwhelmed with symptoms that are keeping you stuck or because you simply feel unsure about how to move forward. I want to assure you that you don't have to walk this alone and you absolutely deserve high-quality support that is going to help you find your way during this time. As a mental health professional, I am passionate about working with individuals struggling with anxiety, depression, grief, life transitions, relationship issues, and behavior change.
        </p>

        <p>
          My approach is a holistic one that uses ideas from several different modalities including both cognitive and trauma-focused. I aim to see people as individuals first and strive to provide tailored support based on their individual needs, making space for us to build a collaborative relationship leading to an increase in wellbeing.
        </p>

        <p>
          In addition to my therapy training, I also have a background as a distress line responder and trainer, supporting individuals in their toughest times to provide relief. In all my work, I bring the passion to help people feel empowered to change their life in meaningful and lasting ways. 
          <a href="https://aestushealth.janeapp.com/#staff_member/1" target="_blank"> Feel free to connect with me today for a free consultation.</a>
        </p>

        {/* <blockquote className="quote">
          “The greatest glory in living lies not in never falling, but in rising every time we fall.”
          <span> – Nelson Mandela</span>
        </blockquote> */}

        {/* Place the ContactForm at the bottom without the image */}
        <ContactForm2 showImage={false} />
      </div>

      <div className="image-section">
        <img src="/alipic2.png" alt="Ali Muhammad" />
        <figcaption><em>Ali Muhammad - Registered Psychotherapist (Qualifying)</em></figcaption>
      </div>
    </section>
  );
}

