import React from "react";
import "./BorderlinePersonalityDisorder.css";

export default function BorderlinePersonalityDisorderSection() {
  return (
    <section className="BorderlinePersonalityDisorder-section">
      <div className="text-section">
        <h2 className="cursive-heading">Understanding Borderline Personality Disorder</h2>
        <p className="first-paragraph">
          Do you ever feel like your emotions are on an uncontrollable, turbulent journey, 
          taking you from the highest highs to the lowest lows in an instant? 
          Borderline Personality Disorder (BPD) can make it seem like you're constantly 
          walking a tightrope, struggling to maintain stable relationships and a clear 
          sense of identity amidst the chaos. It's a battle that often brings about intense 
          feelings, impulsive behaviors, and a pervasive fear of abandonment. As a result, 
          you may find yourself grappling with trust issues, both in yourself and in others, 
          making it challenging to go through the complexities of everyday life.
        </p>

        <div className="image-section-borderlinepersonalitydisorder mobile-image">
          <img src="/womanOnBench.jpg" alt="Woman On Bench" />
        </div>

        <p>
          Unfortunately, BPD is a condition that often 
          is stigmatized and pathologized. People with BPD are often reduced to a label, 
          and their struggles are seen as "problems" rather than as part of their humanity. 
          This narrow view doesn't do justice to the complexity of the person and their 
          experiences, and it can make it harder for them to get the support and understanding 
          they deserve.
        </p>

        <p>
        I offer Dialectical Behavioural Therapy (DBT) as a tool for managing emotional regulation and distress tolerance. However, I first and foremost view BPD through a trauma-focused lens. This means recognizing the roots of pain and honoring the individual behind the struggle — your story, your strengths, and your capacity for healing. I use an approach that in which we not only provide you the skills to regulate your emotions, but also explore the underlying experiences that were the root cause of your inner struggles.
        </p>
        <p>
          At Aestus, I aim to treat every individual with the respect and understanding 
          that they deserve. I do not take your vulnerability for granted and see my patients 
          as individuals first. <a href="https://aestushealth.janeapp.com/#staff_member/1" target="_blank" rel="noopener noreferrer">
            Contact me today to start your journey towards healing and self-understanding.
          </a>
        </p>
      </div>
      
      {/* Desktop image section */}
      <div className="image-section-borderlinepersonalitydisorder desktop-image">
        <img src="/womanOnBench.jpg" alt="Woman On Bench" />
      </div>
    </section>
  );
}
